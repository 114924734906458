document.addEventListener('DOMContentLoaded', function () {

    document.addEventListener('click', function (event) {

        // If the clicked element doesn't have the right selector, bail
        if (!event.target.matches('.js-confirm-btn')) return;

        // Don't follow the link
        event.preventDefault();

        // Log the clicked element in the console
        console.log(event.target);
        var confirmBtn = event.target;
        var container = confirmBtn.closest('.js-confirm-container');
        var iframe = container.querySelector('.js-confirm-iframe-container iframe');

        if (iframe) {
            var source = iframe.getAttribute('data-src');
            iframe.setAttribute('src', source);
            container.classList.add('show-iframe');
        }

    }, false);

}, false);